<template>
  <div>
    Single Notification Here
  </div>
</template>

<script>
  export default {
    data() {
      return {
        currentNotification: {
          id: '',
          title: '',
          body: '',
          date: '',
          status: ''
        },
        items: []
      }
    },
    methods: {
      getNotification: function (notification) {
        for (i = 0; i < this.items.length; i++) {
          if (this.items[i].id === notification) {
            this.currentNotification = this.items[i]
            break
          }
        }
      }
    },
    beforeMount() {
      this.items = this.$store.state.Notifications

      this.getNotification(this.$route.params.itemId)
    }
  }
</script>
